<template>
    <div>
        <div v-if="showModalApresentacaoCora" id="fullscreen-overlay"></div>
        <div class="main-cora-aplicacao">
            <b-navbar class="navbar header">
                <img :src="coraNovo" class="logo-cliente-sesi ml-1" alt="Logo" height="80">
                <div class="d-flex flex-column">
                    <img :src="logoCora" class="logo-header" alt="Logo" >
                    <span class="subtitulo">Assistente virtual do SESI</span>
                </div>
                <div class="ml-auto d-flex align-items-center mb-3">
                    <b-dropdown id="dropdown-1" dropleft class="dropdown-custom" variant="none">
                        <template #button-content>
                            <img :src="dropdownImg"  class="dropdown-toggle-img" alt="Menu" >
                        </template>
                        <b-dropdown-item @click="abrirModalApresentacaoCora()">Apresentação Cora</b-dropdown-item>
                        <b-dropdown-item href="https://www.cists.com.br/" target="_blank">Centro de inovação SESI</b-dropdown-item>
                        <b-dropdown-item href="mailto:cis.contato@sesisc.org.br">Fale conosco</b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-navbar>
            <div class="chat">
                <b-row class="linha" ref="chatContainer">
                    <b-col lg="12" md="12" v-for="(dado, index) in dados" :key="index" >
                        <div v-if="dado.loading" >
                            <div class="d-flex espaco" >
                                <p class="texto-chat ml-1 loading-dots"></p>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-grow-1" v-if="dado.loading == false" >
                            <div class="d-flex espaco">
                                <p class="texto-chat ml-1" v-if="dado.link == false"><span v-html="dado.pergunta_msg"></span></p>
                                <p class="texto-chat texto-privacidade ml-1"
                                    v-if="dado.link">Antes de começarmos, vou te pedir que leia a nossa
                                        <a href="https://drive.google.com/file/d/1hC7ZVZGyHzJhAt7c-FCfSW2la9YyeujB/view"  class="link" target="_blank" v-if="dado.link">
                                            <u>Política de privacidade</u>
                                        </a> 📝.
                                </p>
                            </div>
                            <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado == false" ref="answerOptionsDiv">
                                <button
                                    class="answer-option" @click="pegarProximaIteracao(opcao)"
                                    :class="{ 'lowlight': opcao.cor , 'back-btn': opcao.botao_inicio}"
                                    :style="opcao.botao_inicio ? 'margin: 0 auto; margin-top:20px;' : ''"
                                    :disabled="opcao.desabilita_botao" 
                                    v-for="opcao in dado.opcoes"
                                    :key="opcao.id_opcao"
                                >
                                <span v-html="opcao.descricao_opcao"> </span>
                                </button>
                            </div>
                            <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado">
                                <button class="answer-option d-flex justify-content-start"
                                    @click="adicionarOpcoesEscolhidas(opcao,dado)"
                                    :key="opcaoIndex"
                                    v-for="(opcao,opcaoIndex) in dado.opcoes"
                                    :class="{ 'lowlight': opcao.cor_multivalorado}"
                                    :disabled="opcao.desabilita_botao_multivalorado"
                                >
                                <input type="checkbox" :checked="opcao.botao_multivalorado" :disabled="opcao.desabilita_botao_multivalorado" class="ml-2 cursor-cora-aplicacoes">
                                <label for="" class="form-label label-class ml-2"> <span class="cursor-cora-aplicacoes" v-html="opcao.descricao_opcao"> </span></label>
                                </button>
                            </div>
                            <div class="answer-options-div" v-if="dado.opcoes && dado.multivalorado">
                                <button  @click="pegarProximaIteracaoMultiploSelect()" 
                                    :class="{ 'lowlight': dado.qtd_opcoes_multivaloradas == 0 || 
                                    dado.qtd_opcoes_multivaloradas > dado.multivalorado || 
                                    dado.desabilita_botao_enviar_multivalorado}" 
                                    class="answer-option" :disabled="dado.qtd_opcoes_multivaloradas == 0 || 
                                    dado.qtd_opcoes_multivaloradas > dado.multivalorado || 
                                    dado.desabilita_botao_enviar_multivalorado" 
                                    :title="'escolha de 1 a '+ dado.multivalorado+' opções' " 
                                    style="margin: 0 auto; margin-top:10px
                                ">
                                    Enviar
                                </button>
                            </div>
                        </div>
                        <div class="col-12 p-2 d-flex justify-content-end"  v-if="dado.opcao_escolhida && !dado.multivalorado">
                            <p class="user-msg"><span v-html="dado.opcao_escolhida"></span></p>
                        </div>
                        <div 
                            class="col-12 chat-multivalorado  d-flex justify-content-end"
                            v-for="opcao in dado.opcao_escolhida_multivalorada"
                            v-if="opcao_escolhida_multivalorada.length > 0 && dado.multivalorado"
                            :key="opcao.id_opcao"
                        >
                        <p class="user-msg"><span v-html="opcao"></span></p>
                        </div>
                    </b-col>
                </b-row>
            </div>
          
        </div>
        <ModalApresentacaoCora v-if="showModalApresentacaoCora" @fecharModalApresentacaoCora="fecharModalApresentacaoCora" />
    </div>
</template>

<script>

import { BNavbar, BContainer,BCol, BRow ,BDropdown,BNavItemDropdown,BDropdownItem} from 'bootstrap-vue'
import cora from '@/assets/images/cora/Cora.png'
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import Swal from 'sweetalert2'
import coraNovo from '@/assets/images/cora/cora_new.png'
import logoCora from '@/assets/images/cora/logo_cora.svg'
import dropdownImg from '@/assets/images/cora/dropdown_cora.svg'
import ModalApresentacaoCora from '../ModalApresentacaoCora.vue';

export default {

    components: {
        BNavbar,
        BContainer,
        BCol,
        BDropdown,
        BDropdownItem,
        BNavItemDropdown,
        BRow,
        ModalApresentacaoCora
    },

    data() {
        return {
            cora: cora,
            logoCora: logoCora,
            coraNovo: coraNovo,
            dropdownImg: dropdownImg,
            opcoes: [],
            opcao_clicada: false,
            opcao_escolhida: '',
            buscandoRequisicao:false,
            chave_usuario: '',
            chave_aplicacao: this.$route.params.aplicacao,
            resposta_opcao: '',
            dados: [],
            showModalApresentacaoCora: false,
            multiploSelect: [],
            opcao_escolhida_multivalorada: [],
            api_cora : process.env.VUE_APP_API_START_CORA,
            api_cora_resposta: process.env.VUE_APP_API_PROCESSAMENTO_CORA,
        }
    },

    mounted() {
        document.body.style.setProperty('background-color', '#E4EEF6', 'important');
        this.buscaChaveUsuario()
    },

    methods: {
        async buscaChaveUsuario(){
           let url = this.api_cora+this.chave_aplicacao

            await this.$http.get(url)
            .then(response => {
                this.dados = response.data.resposta_inicial_usuario
                this.chave_usuario = response.data.chave_usuario
                this.verificaLink()

                //Verifica caso tenha questões multivaloradas na primeira iteração quando pega a chave de usuario e adiciona atributo botao_multivalorado e qtd_opcoes_multivaloradas
                this.verificaQuestoesMultivaloradosPrimeiro()
            })
            .catch(error => {
              modalGenericFillableErrorWithReload(error.response.data.error)
            })

            // Aplica Delay pra carregar os pontinhos de depois a pergunta
            this.delayChat(this.dados)
        },

        abrirModalApresentacaoCora(){
            this.showModalApresentacaoCora = true
        },
        fecharModalApresentacaoCora(){
            this.showModalApresentacaoCora = false
        },

        delay(seconds) {
            const milliseconds = seconds * 1000;
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },

        async delayChat(mensagem){
            for (let i = 0; i < mensagem.length; i++) {
                this.$set(mensagem, i, { ...mensagem[i], loading: true })

                await this.delay(2)
                this.$set(mensagem, i, { ...mensagem[i], loading: false })
            }
        },

        async delayChatRespostas(mensagem){
            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottom();
                    });

                    if(mensagem[i].delay_anterior){
                      await this.delay(mensagem[i].delay_anterior);
                      this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                      await this.delay(2)
                      this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }

                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);
            this.scrollChatToBottom();
        },

        async delayChatRespostasMultivaloradas(mensagem, delay = 2){
            for (let i = 0; i < mensagem.length; i++) {
                if(mensagem[i].delayCarragamento){

                    this.$set(mensagem, i, { ...mensagem[i], loading: true })

                    this.$nextTick(() => {
                        this.scrollChatToBottomMultivalor();
                    });
                    await this.delay(0.1);
                    this.scrollChatToBottomMultivalor();

                    if(mensagem[i].delay_anterior){
                        await this.delay(mensagem[i].delay_anterior);
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }else{
                        await this.delay(delay)
                        this.$set(mensagem, i, { ...mensagem[i], loading: false })
                    }


                    mensagem[i].delayCarragamento = false
                }

            }

            // Para scroll focar na ultima div adicionada
            await this.delay(0.1);
            this.scrollUltimoMultivalorado();
        },

        async pegarProximaIteracao(opcao){
            this.opcao_clicada = true
            this.opcao_escolhida = opcao.descricao_opcao

            /*
                Pega ultimo elemento do array de dados e adiciona opcao_escolhida que é o que vai printar
                Para o usuario como resposta clicada
            */
            this.$set(this.dados, this.dados.length - 1, {
                ...this.dados[this.dados.length - 1],
                opcao_escolhida: this.opcao_escolhida,
            });

            this.delayChatRespostas(this.dados)
            this.verificaDesabilitaBotoes(opcao)

            let params = {
                chave_aplicacao: this.chave_aplicacao,
                chave_usuario: this.chave_usuario,
                resposta_opcao: opcao.id_opcao
            }

            let buscandoRequisicao = false

            setTimeout(() => {
                if(buscandoRequisicao){
                    this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                }

            }, 5000)

            buscandoRequisicao = true

            //Busca próxima interação do chat de acordo com a reposta escolhida
            await this.$http.post(this.api_cora_resposta, params, {timeout: 60000})
            .then(response => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(response.data.length > 0){
                    // Adiciona Propriedade delayCarragamento para carregar a div após o delay com os três pontinhos da animação
                    const novasMensagens = response.data.map((res) => ({
                       ...res,
                        delayCarragamento: true,
                        qtd_opcoes_multivaloradas: 0,
                    }));
                    this.dados = [...this.dados, ...novasMensagens];
                    this.verificaBotaoInicio()
                    this.verificaLink()
                }
                this.delayChatRespostas(this.dados)
            })
            .catch(error => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                    modalGenericFillableErrorWithReload('Houve um problema com a sua conexão. Tente novamente mais tarde.')
                }else{
                    modalGenericFillableErrorWithReload('Servidor indisponível. Tente novamente mais tarde.')
                }


            })
        },

        async pegarProximaIteracaoMultiploSelect(){

           let resposta_opcao_multivalorada = this.multiploSelect.map((op) => op.id_opcao)
           this.opcao_escolhida_multivalorada = this.multiploSelect.map((op) => op.descricao_opcao)

           this.$set(this.dados, this.dados.length - 1, {
                ...this.dados[this.dados.length - 1],
                opcao_escolhida_multivalorada: this.opcao_escolhida_multivalorada,
            });

            this.verificaDesabilitaBotoesMultivalorados();

           let params = {
                chave_aplicacao: this.chave_aplicacao,
                chave_usuario: this.chave_usuario,
                resposta_opcao_multivalorada: resposta_opcao_multivalorada
            }

            let buscandoRequisicao = false

            setTimeout(() => {
                if(buscandoRequisicao){
                    this.modalCoraLoading('Houve um problema ao se conectar ao servidor. Tentando reconectar novamente...')
                }

            }, 5000)

            buscandoRequisicao = true

            //await this.$http.post(this.$api.buscaServicosCora, params)
            await this.$http.post(this.api_cora_resposta, params, {timeout: 60000})
            .then(response => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(response.data.length > 0){
                    // Adiciona Propriedade delayCarragamento para carregar a div após o delay com os três pontinhos da animação
                    const novasMensagens = response.data.map((res) => ({
                       ...res,
                        delayCarragamento: true
                    }));
                    this.dados = [...this.dados, ...novasMensagens];
                    this.verificaBotaoInicio()
                    this.verificaLink()

                    //Verifica caso tenha questões multivaloradas na primeira iteração quando pega a chave de usuario e adiciona atributo botao_multivalorado e qtd_opcoes_multivaloradas
                    this.verificaQuestoesMultivaloradosPrimeiro()
                }

                this.delayChatRespostasMultivaloradas(this.dados)
                 // Limpa multiploSelect apenas para esta instância específica
                this.$set(this, 'multiploSelect', []);
            })
            .catch((error) => {
                buscandoRequisicao = false
                this.fecharModalCora()
                if(error.message == "Cannot read properties of null (reading 'status')" || !error.request){
                    modalGenericFillableErrorWithReload('Houve um problema com a sua conexão. Tente novamente mais tarde.')
                }else{
                    modalGenericFillableErrorWithReload('Servidor indisponível. Tente novamente mais tarde.')
                }
            })
        },

        adicionarOpcoesEscolhidas(opcao,dado){
            opcao.botao_multivalorado = !opcao.botao_multivalorado;
            dado.desabilita_botao_enviar_multivalorado = false
            this.multiplaEscolhaSelect(opcao,dado)
        },

        scrollChatToBottom() {
            const lastElement = this.$refs.chatContainer.lastChild.lastElementChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }

        },
        scrollUltimoMultivalorado(){
            let ultimoElemento  = this.$refs.chatContainer.children[this.$refs.chatContainer.children.length - 1];

            if(ultimoElemento.lastElementChild != null){
                ultimoElemento.lastElementChild.lastElementChild.scrollIntoView({ behavior: "smooth" });

                if(this.$refs.answerOptionsDiv){
                    const answerOptionsDiv = this.$refs.answerOptionsDiv[this.$refs.answerOptionsDiv.length - 1];
                     //Verifique se o elemento existe
                    if (answerOptionsDiv) {
                    // Role até o elemento usando scrollIntoView
                        answerOptionsDiv.scrollIntoView({ behavior: 'smooth' });
                    }
                }


            }
        },
        scrollChatToBottomMultivalor() {
            const lastElement = this.$refs.chatContainer.lastChild;

            if (lastElement) {
                lastElement.scrollIntoView({ behavior: "smooth" });
            }
        },


        verificaDesabilitaBotoes(opcao){
            //Percorre array de dados e desabilita botões que já foram clicados
            this.dados[this.dados.length - 1].opcoes.forEach((op) => {
                op.selecionado = op.id_opcao === opcao.id_opcao;
                op.selecionado_cor = op.id_opcao != opcao.id_opcao;
            });

            //Verifica se objeto tem opcoes e se tem desabilita botões que não foram clicados
            this.dados.forEach((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.desabilita_botao = op.selecionado || this.opcao_clicada;
                        op.cor = op.selecionado_cor;
                    });
                }
            });
        },

        verificaDesabilitaBotoesMultivalorados(){
            this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        if(op.multivalorado !== false){
                            if(op.botao_multivalorado){
                                op.cor_multivalorado = !op.botao_multivalorado;
                                op.desabilita_botao_multivalorado = true
                            }else{
                                op.cor_multivalorado = true;
                                op.desabilita_botao_multivalorado = true
                            }
                        }

                    });
                }
            });

            //Desabilita o botão de Enviar de questoes multivaloradas
            this.dados.map((dado) => {
                dado.desabilita_botao_enviar_multivalorado = true
            });
        },

        verificaBotaoInicio(){
           this.dados.map((dado) => {
                if (dado.opcoes) {
                    dado.opcoes.map((op) => {
                        op.botao_inicio = op.descricao_opcao === 'Voltar ao início' || op.descricao_opcao === '<b>Voltar ao início</b>';
                    });
                }
            });
        },

        verificaLink(){
            this.dados.map((dado) => {
               dado.link = dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa <2>. 📝' || dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa Política de Privacidade DASS 21. 📝' || dado.pergunta_msg =='Antes de começarmos, vou te pedir que leia a nossa <b>Política de Privacidade PHQ9</b>. 📝' ||  dado.pergunta_msg == 'Antes de começarmos, vou te pedir que leia a nossa <b>Política de Privacidade DASS 21</b>. 📝'

            });
        },

        multiplaEscolhaSelect(opcao,dado) {
            if (opcao.botao_multivalorado) {              // Adiciona a opção ao array se estiver marcada checkbox
                this.multiploSelect.push(opcao);
            } else {
                // Remove a opção do array se estiver desmarcada
                const index = this.multiploSelect.indexOf(opcao);
                if (index !== -1) {
                    this.multiploSelect.splice(index, 1);
                }

                if(this.multiploSelect.length  <= 1){
                    dado.desabilita_botao_enviar_multivalorado = true
                }
            }

            dado.qtd_opcoes_multivaloradas = this.multiploSelect.length
        },

        verificaQuestoesMultivaloradosPrimeiro(){
            this.dados.map((dado) => {
                if (dado.opcoes && dado.multivalorado !== false) {
                    dado.qtd_opcoes_multivaloradas = 0
                    dado.desabilita_botao_enviar_multivalorado = true
                    dado.opcoes.map((op) => {
                        if(op.botao_multivalorado !== true){
                            op.botao_multivalorado = false
                        }
                    });
                }
            });
        },

        modalCoraLoading(texto){
            let timerInterval;
            Swal.fire({
                width: 550,
                imageUrl: require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
                imageWidth: 80,
                imageHeight: 80,
                title: 'Erro na conexão',
                allowOutsideClick: false,
                html: `<span class="">${texto}</span>`,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            })
        },
        fecharModalCora(){
            Swal.close()
        },
    }

}

</script>

<style>
#fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
}
.modal .modal-header .close {
    display: none;
    z-index: 10;
}
.dropdown-toggle {
    padding: 0  !important;
}
.dropleft .dropdown-toggle::before{
    display: none;
}
.main-cora-aplicacao {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40vw;
    height: 90vh;
    background-color: #F4F4F4;
    border-radius: 6px;
    min-width: 600px;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
/* Logo */
.logo-header{
    width: 205px;
    height: 49px;
}
.logo-cliente-sesi{
    width: 94.86px;
    height: 94.86px;
    margin: 2%;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subtitulo {
    font-size: 12.7684px;
    font-weight: 400;
    margin-top: 10px !important;
    font-style: normal;
    line-height: 16px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
}
.header{
    height: 15%;
    background: rgb(36,109,186);
    background: linear-gradient(150deg, rgba(36,109,186,1) 0%, rgba(4,42,108,1) 60%, rgba(85,44,145,1) 100%);
    display: flex;
    align-items: center;
    color: #fff;
    min-height: 120px;
    border-radius: 6px 6px 0px 0px;
}
.backdrop-aplicacoes{
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.back-btn {
    background: none !important;
    border: 2px solid rgba(0,32,97,.5019607843) !important;
    color: #002060 !important;
    font-weight: 600;
    margin-top: 1em;
}
.answer-option-last{
    width: 50%;
    max-width: 50%;
    margin: 1% auto;
    position: relative;
}
.link{
    color: #000000;
    font-weight: bold;
}

.answer-options-div{
    max-width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 14px;
}
.label-class{
    color: #fff;
}
.cursor-cora-aplicacoes{
    cursor: pointer;
}
.answer-option{
    width: 45%;
    max-width: 45%;
    min-height: 30px;
    padding: 1% 2%;
    background-color: #002060;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px #0000001f;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    margin: 1% 2%;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    cursor: pointer;
    border: none;
}
.opacity-active{
    opacity: 0.8;
}
.chat {
    flex: 1; /* Ocupa todo o espaço disponível */
    background: rgba(244, 244, 244, 0.7);
    background-image: url('../../../../assets/images/cora/cora_backgorund.svg');
    padding: 6% 2.6% 2.5%;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -20px 20px  #0000001f;
    overflow-x: hidden;
}
.chat-multivalorado{
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
}
.espaco{
    margin-bottom: 2.3% !important;
}
.chat p {
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 65%;
    padding: 8px 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
    overflow-wrap: break-word;
    color: #000000;
}
.user-msg {
    margin-left: auto;
    background-color: #2772C0;
    border-radius: 13.68px 0 13.68px 13.68px;
    color: #fff !important;
    margin-bottom: 14px;
    font-size: 14px;
}

.lowlight {
    background-color: #6177A2 !important;
    cursor: auto;
}

.topo{
    height: 112px;
    background: linear-gradient(45deg,#1265BB .32%,#4EA4FE 100%);
    display: flex;
    align-items: center;
    color: #fff;
}
.topo h1 {
    font-size: 2.1rem;
    font-weight: 500;
    color: #fff;

}

.img-cora-chat{
    width: 48px;
    height: 48px;
}
.texto-chat{
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 65%;
    padding: 8px 16px;
    border-radius: 0px 13.68px 13.68px 13.68px;
    box-shadow: 0px 3.65px 5.47px 0px rgba(0, 0, 0, 0.12);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    text-align: justify;
    overflow-wrap: break-word;
    background-color: #fff;
    margin: 0 0 10px;
}

@keyframes dotsAnimation {
    0%, 20% {
        content: ' .';
    }
    40%, 60%{
        content: ' ..';
    }
     80%,100% {
        content: ' ...';
    }

}

.loading-dots::after {
    content: ' ....';
    animation: dotsAnimation 1s infinite;
    display: inline-block;
}


@media (max-width: 768px) {
    .main-cora-aplicacao{
        min-width: 400px;
        width: 100vw;
        height: 100vh;
        top: 0%;
        left: 0%;
        border-radius: 0;
        transform: translate(0);
        overflow: hidden;
    }
    .answer-options-div{
        max-width: 100%;
        margin-left: 0;
    }
    .answer-option{
        width: 100%;
        max-width: 100%;
        margin: 1% 0;
    }

    .chat p {
        max-width: 100%;
    }

}

@media (max-width: 992px) {
    .main-cora-aplicacao{
        min-width: 600px;
    }
}

@media (max-width: 480px) {
    .main-cora-aplicacao {
        min-width: 200px;
        width: 100vw;
        height: 100vh;
        top: 0%;
        left: 0%;
        border-radius: 0;
        transform: translate(0);
        overflow: hidden;
    }
}


@media (max-width: 420px) {
    .logo-header{
        width: 140px;
        height: 37px;
    }
}

</style>
